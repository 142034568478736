/* eslint-disable no-use-before-define */
/* eslint-disable no-prototype-builtins */
import { callKaptureAPI } from '@/api/kapture';
import { BooleanValue, KaptureEnums, MiscellaneousEnums } from '@/GlobalEnums/enums';
import { IUserProfileDetails } from '@/interfaces/common';
import { MOBILE_VIEWPORT } from '@/utils/common/common.constants';
import {
  // environment,
  kaptureChatbotScript,
  kaptureDataSupportKey,
} from '@/utils/common/env.constants';
import { getCookies } from '@/utils/helper/helperFunction';
import { useScreenSize } from '@pwskills/rachnaui/hooks';
import { useRouter } from 'next/router';

export default function useKapture() {
  const router = useRouter();
  const allowedRoutes = [
    '/',
    '/course/[courseSlug]',
    '/category/[categorySlug]',
    '/courses',
    '/online-degree',
    '/degree/[degreeSlug]',
    '/checkout',
  ];
  const { width } = useScreenSize();
  const kaptureCustomerAPI = async (userDetails: IUserProfileDetails) => {
    const payload = {
      contact_person_name: `${userDetails?.firstName} ${userDetails?.lastName}`,
      contact_person_phone: userDetails?.phoneNumber,
      contact_person_email: userDetails?.email,
      customer_code: userDetails?._id,
    };
    /* eslint-disable no-console */
    try {
      await callKaptureAPI(payload);
    } catch (error) {
      // Handle the error as needed, e.g., show a notification to the user
    }
  };
  const stringToURLEncoded = (inputString: string) => {
    if (typeof inputString !== 'string') {
      throw new TypeError('Input must be a string');
    }
    // Encode the string using encodeURIComponent
    return encodeURIComponent(inputString).replace(/\+/g, '%2B');
  };
  const removeIframe = () => {
    const iframe = document.getElementById('kaptureChatIframe');
    if (iframe) {
      iframe?.parentNode?.removeChild(iframe);
    }
  };
  const handleKaptureScript = (mode: string, ref: any) => {
    const iframe = document.getElementById('kaptureChatIframe');
    if (iframe) {
      iframe?.parentNode?.removeChild(iframe);
    }
    if (kaptureChatbotScript === BooleanValue.TRUE && allowedRoutes.includes(router.route)) {
      // Create a new script element with additional attributes
      const newIframe = document.createElement('iframe');
      if (newIframe) {
        if (width > MOBILE_VIEWPORT)
          newIframe.setAttribute(
            'style',
            'visibility: visible; opacity: 1; transform: translateY(0px); transition: 0.3s; position: fixed; right: 30px; bottom: 80px; z-index: 99999; font-family: Poppins, sans-serif; width: 500px; height: 70%; border-radius: 15px'
          );
        else
          newIframe.setAttribute(
            'style',
            'visibility: visible; opacity: 1; transform: translateY(0px); transition: 0.3s; position: fixed; right: 5px; bottom: 120px; z-index: 99999; font-family: Poppins, sans-serif; width: 400px; height: 600px;'
          );
        newIframe.id = 'kaptureChatIframe';
        newIframe?.setAttribute('data-supportkey', kaptureDataSupportKey as string);
        newIframe.setAttribute('chat-for', 'TICKET');
        newIframe.setAttribute('data-server', 'Indian');
        let src = '';
        if (mode === MiscellaneousEnums.LOGGED_IN) {
          src = `https://selfserveapp.kapturecrm.com/cb-v1/web-view/webview_chat.html?&data-supportkey=${kaptureDataSupportKey}&chat-for=TICKET&data-server=Indian&server-host=ms-noauth&script_type=RNF&customer_code=${stringToURLEncoded(
            getCookies(KaptureEnums.KAPTURE_CC) as string
          )}&iv=${stringToURLEncoded(getCookies(KaptureEnums.KAPTURE_IV) as string)}`;
        } else
          src = `https://selfserveapp.kapturecrm.com/cb-v1/web-view/webview_chat.html?&data-supportkey=${kaptureDataSupportKey}&chat-for=TICKET&data-server=Indian&server-host=ms-noauth&script_type=NR`;
        newIframe.src = src;
        ref.current.appendChild(newIframe);
      }
    }
  };
  return {
    kaptureCustomerAPI,
    handleKaptureScript,
    removeIframe,
  };
}
